// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GameVendors-logoSquare-2NW8o{height:25px}.GameVendors-root-3eh6d{padding:10px;display:grid;grid-template-columns:repeat(2,1fr);gap:10px;grid-auto-rows:minmax(100px,auto)}@media screen and (min-width:993px){.GameVendors-root-3eh6d{grid-template-columns:repeat(6,1fr);padding:0}}.GameVendors-item-2-xje{width:100%;font-size:12px;flex-direction:column;border-radius:20px;margin-right:10px;display:flex;justify-content:center;align-items:center;border:1px solid grey;background-color:var(--color-lightblue-6)}.GameVendors-item-2-xje:hover{background:var(--color-babyblue)}.GameVendors-item-2-xje img{max-width:70%;object-fit:contain}.GameVendors-amount-3L8uP{margin-top:10px}", ""]);
// Exports
exports.locals = {
	"logoSquare": "GameVendors-logoSquare-2NW8o",
	"root": "GameVendors-root-3eh6d",
	"item": "GameVendors-item-2-xje",
	"amount": "GameVendors-amount-3L8uP"
};
module.exports = exports;
