export const slugifyURL = text => {
  if (text) {
    const slugifiedURL = text
      .replace(/[A-Z]/g, ' $&') //add space before capital letter
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '') //remove non word char ex: , .
      .replace(/[\s:]+/g, '-') // replace : with -
      .replace(/[\s;]+/g, '-') // replace ; with -
      .replace(/[\s_-]+/g, '-') // replace _ with -
      .replace(/^-+|-+$/g, '');
    return slugifiedURL;
  } else {
    return '';
  }
};

// Carousel type. Banners are filtered by its value:
// 1 - Casino;
// 2 - Live Casino;
// 3 - Sport;
// 4 - Jackpots;
// 5 - Bingo.
export const getEngageBannerType = route => {
  switch (route) {
    case 'lobby':
      return 1;
    case 'liveLobby':
      return 2;
    default:
      return 1;
  }
};

export const getSiteFriendlyURL = url => {
  const pageName =
    url?.includes('info') && url?.length > 1 && url?.split('/')[2];
  if (pageName === false) {
    return url;
  }
  switch (pageName) {
    case 'about-us':
      return 'about';
    case 'terms':
      return 'terms-conditions';
    case 'privacy':
      return 'privacy-policy';
    case 'fair-play':
    case 'fairplay':
      return 'fair-play';
    case 'faq':
      return 'faq';
    case 'security':
      return 'security';
    case 'cookie-policy':
    case 'cookiepolicy':
      return 'cookie-policy';
    case 'loyalty-club':
    case 'loyaltyclub':
      return 'loyalty-club';
    case 'responsible':
    case 'responsible-gaming':
    case 'responsible-gaming':
      return 'responsible-gaming';
    case 'payment':
    case 'payments':
      return 'payments';
    case 'bonus-policy':
      return 'bonus-terms-conditions';
    default:
      return 'terms-conditions';
  }
};
