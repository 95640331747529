import React from 'react';
import ic from './arrow.svg';

function Arrow({
  direction,
  onClick,
  customArrowClass = '',
  isRef = null,
  border = false,
  ariaLabel = null,
}) {
  return (
    <button
      className={`flex items-center justify-center rounded-3xl bg-darkblue ${
        direction !== 'right' ? 'swiper-button-next' : 'swiper-button-prev'
      } ${
        !customArrowClass.length
          ? 'w-10 h-10  text-btn-text bg-carousel-control hover:bg-opacity-80 transition-colors duration-300'
          : customArrowClass
      }`}
      onClick={onClick}
      ref={isRef}
      type="button"
      aria-label={ariaLabel}
    >
      <img
        src={ic}
        className={
          direction !== 'right'
            ? 'inline-block w-5 h-4 fill-current'
            : 'inline-block w-5 h-4 transform rotate-180 fill-current'
        }
        alt=""
        width={20}
        height={16}
      />
    </button>
  );
}

export default Arrow;
