import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {defineMessages, injectIntl} from 'react-intl';
import s from './GameLabelPercentage.scss';
import classnames from 'classnames';

const messages = defineMessages({
  labelNew: {
    id: 'label.new',
    defaultMessage: 'New',
    description: `the label on a game if it's new`,
  },
});

@withStyles(s)
@injectIntl
class GameLabel extends React.Component {
  render() {
    const { intl, type } = this.props;
    return (
      <div className={classnames(s.root,{[s.cold] : type ==='cold', [s.hot] : type ==='hot'})}>
        <span className={s.labelText}>
          {this.props.percentage}%
        </span>
      </div>
    );
  }
}

export default GameLabel;
