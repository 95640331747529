import React from 'react';
import { connect, useSelector } from 'react-redux';
import LandingOffer from '../../components/LandingOffer';
import GameBrowser from '../../components/GameBrowser';

const TracksExplanation = React.lazy(() =>
  import('../../components/TracksExplanation'),
);
const HomeSeo = React.lazy(() => import('./HomeSeo'));
const TopWinners = React.lazy(() => import('../../components/TopWinners'));
const Faq = React.lazy(() => import('../../components/Faq'));
const Jackpots = React.lazy(() => import('../../components/Jackpots'));

import SsrCompatibleSuspense from '../../components/SsrCompatibleSuspense';
import { useIntl } from 'react-intl';
const HomePublic = ({ children }) => {
  const intl = useIntl();
  const IpCountry = useSelector((state) => state.user.IpCountry);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const licenseType = useSelector((state) => state.license.type);
  return (
    <main className="flex flex-col items-center justify-center w-full flex-grow">
      {children}
      <LandingOffer />
      <div className="relative w-full flex flex-col justify-center items-center lg:px-10 ">
        <GameBrowser />
      </div>
      {!isAuthenticated && (
        <SsrCompatibleSuspense fallback={<div />}>
          <div className="container">
            <HomeSeo />
          </div>
        </SsrCompatibleSuspense>
      )}
      {!isAuthenticated && (
        <SsrCompatibleSuspense fallback={<div />}>
          <TopWinners />
        </SsrCompatibleSuspense>
      )}

      {!isAuthenticated &&
      (licenseType.toLowerCase() === 'uk' ||
        intl.locale === 'en-gb' ||
        intl.locale === 'en-in') ? (
        <SsrCompatibleSuspense fallback={<div />}>
          <TracksExplanation />
        </SsrCompatibleSuspense>
      ) : (
        !isAuthenticated &&
        IpCountry !== 'DE' && (
          <SsrCompatibleSuspense fallback={<div />}>
            <TracksExplanation />
          </SsrCompatibleSuspense>
        )
      )}
      {!isAuthenticated && (
        <SsrCompatibleSuspense fallback={<div />}>
          <Jackpots />
        </SsrCompatibleSuspense>
      )}
      {!isAuthenticated && (
        <SsrCompatibleSuspense fallback={<div />}>
          <Faq />
        </SsrCompatibleSuspense>
      )}
    </main>
  );
};

export default HomePublic;
