// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./label.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./label-cold.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./label-hot.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".GameLabelPercentage-root-1ecBu{z-index:20;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:100%;position:absolute;box-sizing:border-box;height:30px;top:5px;left:0;min-width:40px;background-repeat:no-repeat}.GameLabelPercentage-root-1ecBu.GameLabelPercentage-cold-w1y8r{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.GameLabelPercentage-root-1ecBu.GameLabelPercentage-hot-2i3ww{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@media screen and (min-width:993px){.GameLabelPercentage-root-1ecBu{height:30px;top:5px;left:0;min-width:40px}}.GameLabelPercentage-labelText-24C5g{display:block;min-width:40px;line-height:30px;text-transform:uppercase;font-size:.75rem;height:30px;text-align:center;font-weight:500;margin-right:15px;background-color:#f0e844;padding-left:10px;box-sizing:border-box}@media screen and (min-width:993px){.GameLabelPercentage-labelText-24C5g{height:30px;margin-right:15px;line-height:30px}}.GameLabelPercentage-cold-w1y8r .GameLabelPercentage-labelText-24C5g{background-color:#69f;color:#fff}.GameLabelPercentage-hot-2i3ww .GameLabelPercentage-labelText-24C5g{background-color:#dd7572;color:#fff}", ""]);
// Exports
exports.locals = {
	"root": "GameLabelPercentage-root-1ecBu",
	"cold": "GameLabelPercentage-cold-w1y8r",
	"hot": "GameLabelPercentage-hot-2i3ww",
	"labelText": "GameLabelPercentage-labelText-24C5g"
};
module.exports = exports;
