import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import isMobile from 'ismobilejs';
import { defineMessages, injectIntl } from 'react-intl';
import slugify from 'slugify';
import {
  fetchData,
  gamebrowserSetCategory,
  getGames,
  getLiveLobby,
  resetFilter,
  setCategory,
  setGameCategory,
} from '../../actions/gamebrowser';
import GameItemCasino from './GameItemCasino';
import Link from '../Link';
import { scrollTo } from '../../core/utils';
import arrow from '../../../public/img/icons/arrow-right.svg';
import s from '../Form/ActionButtons.scss';

const numMobileGames = 30;
const numDesktopGames = 9;

const messages = defineMessages({
  noGames: {
    id: 'text.noGames',
    defaultMessage: '...mmmmm, nothing found here...',
    description: 'Message when no games are found',
  },
  textNewGames: {
    id: 'text.newGames',
    defaultMessage: 'New',
    description: 'The category name for the gambrowser navigator',
  },
  featured: {
    id: 'text.featured',
    defaultMessage: 'Featured',
    description: 'The category name for the gambrowser navigator',
  },
  videoslots: {
    id: 'slots',
    defaultMessage: 'Online slots',
    description: 'General word',
  },
  classicslots: {
    id: 'text.classicslots',
    defaultMessage: 'Classic slots',
    description: 'The category name for the gambrowser navigator',
  },
  jackpotgames: {
    id: 'text.jackpot',
    defaultMessage: 'Jackpot',
    description: 'The category name for the gambrowser navigator',
  },
  christmas: {
    id: 'text.christmas',
    defaultMessage: 'Christmas',
    description: 'The category name for the gambrowser navigator',
  },

  eligableforbonus: {
    id: 'text.eligableforbonus',
    defaultMessage: 'Eligible for bonus',
    description: 'The category name for the gambrowser navigator',
  },
  tablegames: {
    id: 'text.table',
    defaultMessage: 'Table games',
    description: 'The category name for the gambrowser navigator',
  },
  livegames: {
    id: 'text.live',
    defaultMessage: 'Live casino',
    description: 'The category name for the gambrowser navigator',
  },
  slugfeatured: {
    id: 'slug.featured',
    defaultMessage: 'featured',
    description: 'The SEO description for the home page',
  },
  slugvideoslots: {
    id: 'slug.videoslots',
    defaultMessage: 'slots',
    description: 'The SEO description for the home page',
  },
  slugclassicslots: {
    id: 'slug.classicslots',
    defaultMessage: 'classic-slots',
    description: 'The SEO description for the home page',
  },
  slugchristmas: {
    id: 'slug.christmas',
    defaultMessage: 'christmas',
    description: 'The SEO description for the home page',
  },
  slugjackpotgames: {
    id: 'slug.jackpotGames',
    defaultMessage: 'jackpot-games',
    description: 'The SEO description for the home page',
  },
  slugtablegames: {
    id: 'slug.tableGames',
    defaultMessage: 'table-games',
    description: 'The SEO description for the home page',
  },
  slugLobby: {
    id: 'slug.lobby',
    defaultMessage: 'lobby',
    description: 'The SEO description for the home page',
  },
  lastplayed: {
    id: 'text.LastPlayed',
    defaultMessage: 'Last Played',
    description: 'The category name for the category to search',
  },
  newgames: {
    id: 'text.newGames',
    defaultMessage: 'New',
    description: 'The category name for the gambrowser navigator',
  },
  labelViewAll: {
    id: 'text.viewAll',
    defaultMessage: 'Or view all games available',
    description: 'The button below the gamebrowser navigation to view more',
  },
});

const GridFeaturedCategory = ({
  tables,
  category,
  subCategory,
  categoryname,
  intl,
  isMobile,
  browser,
  games,
  showlabel,
  setGameCategory,
  setCategory,
  location,
  details,
}) => {
  const selectGameCategory = (event) => {
    const category = event.currentTarget.getAttribute('data-category');
    const subCategory = event.currentTarget.getAttribute('data-subcategory');
    setGameCategory(category, subCategory);
  };

  let showNumberOfGames = 30;

  return (
    <div className="w-full  mt-5 mb-4 ">
      <div className="pl-4 lg:pl-0 w-full mb-2 flex items-center justify-between">
        <h3 className="text-[#008AFC] font-baloo text-[22px] leading-none font-bold uppercase">
          {category?.name}
        </h3>
        {categoryname !== 'FEATURED' &&
          categoryname !== 'NEWGAMES' &&
          (category.numResults || _.size(category.instantGamesOrder)) >
            showNumberOfGames && (
            <Link
              to="lobby"
              params={{
                category: slugify(category.name, { lower: true }),
                lobby: intl.formatMessage(messages.slugLobby),
              }}
              transition={false}
              className="text-sm font-baloo font-medium text-[#525252] uppercase hover:text-darkblue  lg:mr-0 mr-3"
              onClick={selectGameCategory}
              data-category={slugify('casino', {
                replacement: '-',
                lower: true,
              })}
              data-subcategory={slugify(category?.name, {
                replacement: '-',
                lower: true,
              })}
            >
              {intl.formatMessage(messages.labelViewAll)}{' '}
              <span className="inline-flex items-center">
                {category?.instantGamesOrder?.length}{' '}
                <svg width="15" height="10" className="ml-1 fill-current">
                  <use xlinkHref={`${arrow}#root`} />
                </svg>
              </span>
            </Link>
          )}
      </div>
      <div className="px-4 lg:p-0 max-w-full lg:grid grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-9 gap-3 gap-y-6 whitespace-nowrap lg:whitespace-normal overflow-auto lg:overflow-visible">
        {_.size(category.instantGamesOrder) > 0 &&
          category.instantGamesOrder
            .filter((game) => games[game])
            .slice(0, isMobile ? numMobileGames : numDesktopGames)
            .map((game, i) => (
              <GameItemCasino
                containerClassName={
                  i >= 6 && i < 9
                    ? 'lg:hidden xl:hidden 2xl:block'
                    : i >= 9
                    ? '2xl:hidden'
                    : ''
                }
                key={`${categoryname}_${game}`}
                game={games[game]}
                loading={location === 0 ? 'eager' : 'lazy'}
                showLabel
                index={i}
                details={details}
              />
            ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tables: state.live.tables,
  licenseType: state.license.type,
  isAuthenticated: state.auth.isAuthenticated,
  categories: state.gamebrowser.featureCategories,
  isMobile: state.device.isMobile,
  IpCountry: state.user.IpCountry,
  baseCurrency: state.wallet.baseCurrency,
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(getGames()),
  getLiveLobby: () => dispatch(getLiveLobby()),
  setGameCategory: (category, subCategory) =>
    dispatch(setGameCategory(category, subCategory)),
  setCategory: (category) => {
    dispatch(gamebrowserSetCategory(category));
    dispatch(resetFilter());
    dispatch(setCategory(category));
    dispatch(fetchData()).then(() => {
      scrollTo(window, 0.8, {
        scrollTo: {
          y: '#gameBrowser',
          offsetY: isMobile.any ? 60 : 0,
          autoKill: false,
        },
      });
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GridFeaturedCategory));
