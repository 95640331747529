import React from 'react';

class LoadingCards extends React.PureComponent {
  render() {
    return (
      <>
        <div className="aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden  bg-black/20 animate-pulse" />
        <div className="aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden  bg-black/20 animate-pulse" />
        <div className="aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden  bg-black/20 animate-pulse" />
        <div className="aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden  bg-black/20 animate-pulse" />
        <div className="aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden  bg-black/20 animate-pulse" />
      </>
    );
  }
}

export default LoadingCards;
