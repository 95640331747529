import _ from 'lodash';
import React from 'react';
import netentcolor from '../../../public/img/logos/netentcolor.svg';

const VENDORLOGO = {
  NetEnt: netentcolor,
  // 'EvolutionGaming' : evolutiongaming,
  // 'Ezugi' : ezugi,
};

class GameVendorImage extends React.PureComponent {
  render() {
    const { vendor, className, alt } = this.props;

    if(!vendor) {
      return null;
    }
    return (vendor &&
      <img
        src={_.get(
          VENDORLOGO,
          vendor,
          `/img/logos/landscape/${vendor.replace(/ /g,'').toLowerCase()}.png`,
        )}
        className={className}
        alt={alt}
        loading="lazy"
      />
    );
  }
}

export default GameVendorImage;
