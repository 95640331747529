import React from 'react';
import { useDispatch } from 'react-redux';
import logo from './logo-horizontal.svg';
import Link from '../Link';
import { setGameCategory } from '../../actions/gamebrowser';
import plfBG from '../SideBar/plf-bg.png';

const TopBarLogo = ({ children, showBg }) => {
  const dispatch = useDispatch();

  const clearGameBrowser = () => {
    dispatch(setGameCategory('', ''));
  };
  return (
    <div
      className={`flex flex-col w-full lg:rounded-[20px]  bg-[url('/img/plf-bg.png'),linear-gradient(180deg,rgba(0,134,255,1),rgba(4,201,196,1),rgba(42,23,145,1))]  ${
        !showBg ? 'lg:bg-none' : ''
      }`}
      role="navigation"
    >
      <Link
        to="home"
        className="w-full flex items-center justify-center lg:hidden"
        onClick={clearGameBrowser}
      >
        <img
          src={logo}
          alt="logo"
          className="my-[17px] w-[170px]"
          height="20"
          width="170"
        />
      </Link>
      {children}
    </div>
  );
};

export default TopBarLogo;
