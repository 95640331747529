import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GameVendorImage from '../../GameVendorImage';
import useStyles from "isomorphic-style-loader/useStyles";
import s from "./GameVendors.scss";
import {setVendor} from "../../../actions/gamebrowser";

const GameVendors = ({toggleVendorFilter}) => {
  useStyles(s);
  const dispatch = useDispatch();
  const vendors = useSelector(
    (state) => state.gamebrowser.vendors,
  );

  const selectVendor = (event) => {
    const vendor = event.currentTarget.getAttribute('data-vendor');
    dispatch(setVendor(vendor));
    toggleVendorFilter();
  };

  console.log('vendors',vendors);
  return <div className={s.root}>{Object.keys(vendors).map((vendorName, index) => <a onClick={selectVendor} data-vendor={vendorName} className={s.item}><GameVendorImage
    vendor={vendorName}
    className={s.logoSquare}
    alt="Game provided by"
  />
  <div className={s.amount}>{vendors[vendorName]} Games</div>
  </a>)}</div>
}
export default GameVendors;
