import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

const messages = defineMessages({
  labelPopulair: {
    id: 'label.populair',
    defaultMessage: 'Hot',
    description: "the label on a game if it's new",
  },
});

@injectIntl
class GameLabelPopulair extends React.Component {
  render() {
    const { intl } = this.props;
    return (
      <span className="absolute z-10 top-1.5 right-1.5 md:right-3 md:top-3 rounded bg-gradient-to-br from-[#2E89FF] to-[#FF2FE7] text-xs font-baloo text-white font-bold px-1.5">
        {intl.formatMessage(messages.labelPopulair)}
      </span>
    );
  }
}

export default GameLabelPopulair;
