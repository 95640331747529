import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import isMobile from 'ismobilejs';
import InfiniteScroll from 'react-infinite-scroller';
import { resetFilter, setGameCategory } from '../../actions/gamebrowser';
import GameItemCasino from './GameItemCasino';
import { scrollTo, unslugify } from '../../core/utils';
import LoadingCards from '../LoadingCards/LoadingCards';

import GameVendors from './GameVendors';

const messages = defineMessages({
  noGames: {
    id: 'text.noGames',
    defaultMessage: '...mmmmm, nots.hing found here...',
    description: 'Message when no games are found',
  },
  textNewGames: {
    id: 'text.newGames',
    defaultMessage: 'New',
    description: 'The category name for te gambrowser navigator',
  },
  all: {
    id: 'text.all',
    defaultMessage: 'All',
    description: 'The category name for te gambrowser navigator',
  },
  featured: {
    id: 'text.featured',
    defaultMessage: 'Featured',
    description: 'The category name for te gambrowser navigator',
  },
  videoslots: {
    id: 'slots',
    defaultMessage: 'Online slots',
    description: 'General word',
  },
  classicslots: {
    id: 'text.classicslots',
    defaultMessage: 'Classic slots',
    description: 'The category name for te gambrowser navigator',
  },
  christmas: {
    id: 'text.christmas',
    defaultMessage: 'Christmas',
    description: 'The category name for te gambrowser navigator',
  },
  jackpotgames: {
    id: 'text.jackpot',
    defaultMessage: 'Jackpot',
    description: 'The category name for te gambrowser navigator',
  },
  eligablebonusgames: {
    id: 'text.eligableforbonus',
    defaultMessage: 'Eligible for bonus',
    description: 'The category name for te gambrowser navigator',
  },
  tablegames: {
    id: 'text.table',
    defaultMessage: 'Table games',
    description: 'The category name for te gambrowser navigator',
  },
  livegames: {
    id: 'text.live',
    defaultMessage: 'Live casino',
    description: 'The category name for te gambrowser navigator',
  },
  slugFeatured: {
    id: 'slug.featured',
    defaultMessage: 'featured',
    description: 'The SEO description for the home page',
  },
  slugLobby: {
    id: 'slug.lobby',
    defaultMessage: 'lobby',
    description: 'The SEO description for the home page',
  },
  results: {
    id: 'text.results',
    defaultMessage: 'Results',
    description: 'The category name for te gambrowser navigator',
  },
  loadingGames: {
    id: 'text.loadingGames',
    defaultMessage: 'Loading Games',
    description: 'The category name for te gambrowser navigator',
  },
});

@connect(
  (state) => ({
    // isLoading: state.app.isLoading,
    hasGames: _.some(state.gamebrowser.games),
    isGamesLoaded: state.aspire.isGamesLoaded,
    // gamebrowser: state.gamebrowser,
    isLoading: state.gamebrowser.isLoading,
    games: state.gamebrowser.selectedGames,

    gameDataGames: state.gamebrowser.gameData.games,
    filter: state.gamebrowser.filter,
    selectedCategory: state.gamebrowser.selectedCategory,
    selectedSubCategory: state.gamebrowser.selectedSubCategory,
    tables: state.live.tables,
    lobbyType: state.router.activeRoute.params.lobbyType,
    greaterThan: state.browser.greaterThan,
    isGameBrowserActive: state.navigation.isGameBrowserActive,
    isGoToTopActive: state.gamebrowser.isGoToTopActive,
    isBottomNavigationPinned: state.navigation.bottomMenuStatus === 'pinned',
  }),
  (dispatch) => ({
    setGameCategory: (category, subCategory) => {
      dispatch(resetFilter());
      dispatch(setGameCategory('', ''));
    },
  }),
)
@injectIntl
class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.getItemsPerPage(),
      hasMoreItems: true,
      isLoading: true,
    };
    this.loadCategory = this.loadCategory.bind(this);
  }

  getItemsPerPage() {
    let itemsPerPage;
    const defaultNumRows = 4;
    switch (true) {
      case this.props.greaterThan.large:
        itemsPerPage = 6 * defaultNumRows;
        break;
      case this.props.greaterThan.medium:
        itemsPerPage = 5 * defaultNumRows;
        break;
      case this.props.greaterThan.small:
        itemsPerPage = 4 * defaultNumRows;
        break;
      case this.props.greaterThan.extraSmall:
        itemsPerPage = 4 * defaultNumRows;
        break;

      default:
        itemsPerPage = 3 * defaultNumRows;
    }

    return itemsPerPage;
  }

  loadCategory(category) {
    // this.props.closeFilterSeachBar();
    this.props.setGameCategory('casino', '');

    // eslint-disable-next-line no-undef
    scrollTo(window, 0.5, {
      scrollTo: {
        y: '#gameBrowser',
        autoKill: false,
        offsetY: isMobile.any ? 60 : 0,
      },
    });
  }

  loadMore() {
    const { games } = this.props;
    if (games && this.state.items >= games.length) {
      this.setState({ hasMoreItems: false });
    } else {
      setTimeout(() => {
        this.setState({ items: this.state.items + this.getItemsPerPage() });
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isGamesLoaded && !prevProps.isGamesLoaded) {
      const category = this.props.selectedCategory;
      //this.props.setCategory(category);
      // this.props.getLastPlayed().then(games => {
      //   const allResults = _.slice(
      //     games,
      //     0,
      //     prevProps.isMobile ? numMobileGames : numDesktopGames,
      //   );
      //   this.setState({
      //     hasLastPlayed: true,
      //     listing: { ...prevState.listing, LASTPLAYED: allResults },
      //   });
      // });
    }

    if (
      !this.props.searchIsopen &&
      prevProps.games &&
      (this.props.selectedCategory !== prevProps.selectedCategory ||
        prevProps?.games?.length !== this.props?.games?.length)
    ) {
      this.setState({ hasMoreItems: true, items: this.getItemsPerPage() });
    }
  }

  // showPager() {
  //   const { filter, games } = this.props.gamebrowser;
  //   return games.length < filter.count;
  // }

  toTop() {
    scrollTo(window, 0.5, {
      scrollTo: {
        y: '#gameBrowser',
        autoKill: false,
        offsetY: isMobile.any ? 60 : 0,
      },
    });
  }

  render() {
    const {
      games,
      tables,
      hasGames,
      // isLoading,
      intl,
      filter,
      searchIsopen,
      closeGameSearchBar,
      filterIsopen,
      isGameBrowserActive,
      isBottomNavigationPinned,
      isGoToTopActive,
      gameDataGames,

      categoryname,
      selectedCategory,
      selectedSubCategory,
      toggleVendorFilter,
    } = this.props;

    const { isLoading } = this.state;
    const loadingGames = this.props.isLoading || false;

    console.log('GRID props', this.props);
    console.log('GRID gameDataGames', gameDataGames);
    if (!gameDataGames || (games?.length === 0 && loadingGames)) {
      return (
        <div className=" grid lg:grid-cols-6   xl:grid-cols-6 2xl:grid-cols-9 lg:gap-3 gap-y-6 md:grid-cols-5 gap-2 sm:grid-cols-3 grid-cols-2">
          <LoadingCards height="50" />

          {/* <div>{intl.formatMessage(messages.loadingGames)}</div> */}
        </div>
      );
    }

    if (!loadingGames && games.length === 0) {
      return <div className="">{intl.formatMessage(messages.noGames)}</div>;
    }
    return (
      <div className="w-full  mt-5 mb-4 lg:px-0 px-4" id="gridGames">
        {filterIsopen && (
          <GameVendors toggleVendorFilter={toggleVendorFilter} />
        )}
        <div className="  w-full mb-2 flex items-center justify-between">
          {/*<div className={s.nameContainer}>*/}
          {/*<Link*/}
          {/*  to="lobby"*/}
          {/*  transition={false}*/}
          {/*  targetHash="gameBrowser"*/}
          {/*  params={{*/}
          {/*    category: intl.formatMessage(messages.slugFeatured),*/}
          {/*    lobby: intl.formatMessage(messages.slugLobby),*/}
          {/*  }}*/}
          {/*  onClick={() => {*/}
          {/*    this.loadCategory('FEATURED');*/}
          {/*    closeGameSearchBar();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <svg width="20" height="20" className={s.arrowLeft}>*/}
          {/*    <use xlinkHref={`${arrow}#root`} />*/}
          {/*  </svg>*/}
          {/*</Link> */}
          <h5 className="text-[#008AFC] text-xl font-baloo lg:text-[22px] leading-none font-bold uppercase">
            {searchIsopen ||
            filterIsopen ||
            _.size(filter.vendors) > 0 ||
            _.size(filter.themes) > 0 ||
            _.size(filter.variance) > 0
              ? intl.formatMessage(messages.results)
              : unslugify(selectedSubCategory)}{' '}
            {''}({games.length})
          </h5>
        </div>

        <InfiniteScroll
          className="grid lg:grid-cols-6   xl:grid-cols-6 2xl:grid-cols-9 lg:gap-3 gap-y-6 md:grid-cols-5 gap-2 sm:grid-cols-3 grid-cols-2"
          loadMore={this.loadMore.bind(this)}
          hasMore={this.state.hasMoreItems}
          loader={<LoadingCards />}
          useWindow
        >
          {games &&
            games.slice(0, this.state.items).map((game, i) => {
              return (
                <GameItemCasino
                  key={game.id}
                  game={gameDataGames[game]}
                  lazyLoad={i > 10}
                  // className={s.gameItem}
                  index={i}
                  showlabel
                  fullwidth
                />
              );
              // if (game.Live)
              //   return (
              //     <GameItemLiveGeneral
              //       className={s.gameItem}
              //       key={game.id}
              //       game={game}
              //       table={_.get(tables, game.tableID)}
              //       lazyLoad={false}
              //     />
              //   );
            })}
        </InfiniteScroll>
        {/* <div
          className={cx(s.toTopContainer, 'container', {
            [s.show]: isGoToTopActive,
            [s.hide]: !isGoToTopActive,
            [s.isBottomNavigationPinned]: isBottomNavigationPinned,
          })}
          id="toTopContainer"
        >
          <div className={s.toTop} onClick={this.toTop}>
            <svg width="20" height="20" className={s.arrowTop}>
              <use xlinkHref={`${arrow}#root`} />
            </svg>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Grid;
/*
{this.showPager() && <Pager />}
*/
