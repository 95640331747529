import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIntl } from 'react-intl';
import { Navigation, Pagination } from 'swiper';
import { getEngageBannerType, getSiteFriendlyURL } from '../../data/constant';
import { closeModal, openModal } from '../../actions/modal';
import { startGame } from '../../actions/game';
import history from '../../core/history';
import gamling from './gambling-commission.png';
import shield from './shield.svg';
import { setEngagedBannerDetails } from '../../actions/aspire';
import Arrow from './Arrows/Arrow';
import TopBarLogo from '../TopBarLogo';
import { Helmet } from 'react-helmet-async';
const LandingOffer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isBannerReady = useSelector((state) => state.aspire.isBannerReady);
  const engaggedBannerDetails = useSelector(
    (state) => state.aspire.engaggedBannerDetails,
  );
  const baseCurrency = useSelector((state) => state.wallet.baseCurrency);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentRouter = useSelector((state) => state.router.activeRoute.name);
  const isMobile = useSelector((state) => state.device.isMobile);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const carouselType = getEngageBannerType(currentRouter);
    const currency = window?.AsgFW.getCurrency() || baseCurrency;
    const isPayNPlayAvailable = window?.AsgFW.isPayNPlayAvailable() || false;
    const bannerData = window?.AsgFW?.getAspireEngageBanners(
      carouselType,
      [],
      2, // https://docs.aspireglobal.com/en_US/sdk-reference/promotions-apis#getaspireengagebanners-1
      isPayNPlayAvailable,
      currency,
    );
    console.log('bannerData--bannerData1', bannerData);
    // }, [1000]);

    if (bannerData) {
      dispatch(setEngagedBannerDetails(bannerData));
    }
    // dispatch({
    //   type: 'SET_ENGAGGED_BANNER_DETAILS',
    //   payload: bannerData,
    // });

    let sameObject = false;
    try {
      sameObject =
        JSON.stringify(bannerData) ===
        JSON.stringify(window?.App?.state?.aspire?.engaggedBannerDetails);
    } catch (e) {}
    try {
      if (!sameObject && bannerData) {
        console.log('POST BANNERS TO CACHE', bannerData);
        fetch('/api/ssr-banners', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bannerData,
            carouselType,
            isPayNPlayAvailable,
            currency,
            isMobile,
            isAuthenticated,
          }),
        });
      }
    } catch (e) {
      console.log('/api/ssr-games', e);
    }
    // }
  }, [currentRouter, isBannerReady]);

  const getBannerCTA = (item) => {
    console.log('item--item', item);
    switch (item?.type) {
      case 1:
        switch (item?.ActionID) {
          case 'Registration':
            dispatch(openModal('REGISTER_MODAL'));
            break;
          case 'Deposit':
            dispatch(openModal('DEPOSIT_MODAL'));
            break;
          case 'Login':
            dispatch(openModal('AUTH_MODAL'));
            break;
          case 'Chat':
            isAuthenticated
              ? window?.AsgFW.showContactUs()
              : dispatch(openModal('AUTH_MODAL'));
            break;
          case 'InfoPageTerms':
            history.push(`/${intl.locale}/terms-conditions`);
            break;
          default:
            break;
        }
        break;
      case 2:
        const URL = getSiteFriendlyURL(item?.ActionID);
        history.push(
          `/${intl.locale}/${URL?.startsWith('/') ? URL.slice(1, 1000) : URL}`,
        );
        break;
      case 3:
        dispatch(closeModal('GAME_SEARCH_MODAL'));
        isAuthenticated
          ? dispatch(startGame({ id: item?.ActionID }, 'M'))
          : dispatch(startGame({ id: item?.ActionID }, 'D'));
        break;
      case 4:
        isAuthenticated
          ? dispatch(openModal('DEPOSIT_MODAL'))
          : dispatch(openModal('AUTH_MODAL'));
        break;
      default:
        break;
    }
  };

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const swiperRef = useRef();
  return (
    <TopBarLogo>
      <div className="w-full lg:px-10 lg:pt-10 bg-red">
        <div className="relative w-full lg:rounded-tl-lg lg:rounded-tr-lg">
          {engaggedBannerDetails?.length > 1 && (
            <>
              <div className="absolute left-0 z-10 hidden -mt-8 transform -translate-x-1/2 -translate-y-1/2 top-1/2 lg:block">
                <Arrow
                  onClick={() => swiperRef.current?.slidePrev()}
                  direction="left"
                  isRef={navigationPrevRef}
                  ariaLabel="Previous slide"
                  border
                />
              </div>
              <div className="absolute right-0 z-10 hidden -mt-8 transform translate-x-1/2 -translate-y-1/2 top-1/2 lg:block">
                <Arrow
                  onClick={() => swiperRef.current?.slideNext()}
                  direction="right"
                  isRef={navigationNextRef}
                  ariaLabel="Next slide"
                  border
                />
              </div>
            </>
          )}
          <div
            className={
              'w-full aspect-[320/123] lg:aspect-[120/19] lg:rounded-tl-lg lg:rounded-tr-lg overflow-hidden'
            }
          >
            {engaggedBannerDetails && (
              <Swiper
                modules={[Pagination, Navigation]}
                pagination
                loop
                className="overflow-hidden top-banner"
                spaceBetween={0}
                slidesPerView={1}
                speed={300}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                autoplay={
                  engaggedBannerDetails?.length > 1
                    ? {
                        delay: 5000,
                        speed: 300,
                        disableOnInteraction: false,
                      }
                    : false
                }
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(e) => {
                  setActiveIndex(e.activeIndex);
                }}
              >
                {engaggedBannerDetails?.map((item, index) => (
                  <SwiperSlide key={index}>
                    {index === 0 && (
                      <Helmet>
                        <link
                          rel="preload"
                          as="image"
                          href={`https://www.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${item?.content?.image.replace(
                            'whtml',
                            'mwnc',
                          )}`}
                          media="(max-width: 799px)"
                        />
                        <link
                          rel="preload"
                          as="image"
                          href={`https://www.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${item?.content?.image.replace(
                            'mwnc',
                            'whtml',
                          )}`}
                          media="(min-width: 1024px)"
                        />
                      </Helmet>
                    )}

                    <div
                      onClick={() => {
                        item?.actions?.banner &&
                          getBannerCTA(item?.actions?.banner);
                      }}
                      className="relative w-full aspect-[320/123] lg:aspect-[120/19]"
                    >
                      <picture>
                        <source
                          media="(max-width: 799px)"
                          srcSet={`https://www.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${item?.content?.image.replace(
                            'whtml',
                            'mwnc',
                          )}`}
                        />
                        <source
                          media="(min-width: 1024px)"
                          srcSet={`https://www.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${item?.content?.image.replace(
                            'mwnc',
                            'whtml',
                          )}`}
                        />
                        <img
                          src={`https://www.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1/${item?.content?.image}`}
                          alt=""
                          className="object-cover w-full"
                          loading="eager"
                          fetchpriority={index === 0 ? 'high' : 'low'}
                          height={351}
                          width={2218}
                        />
                      </picture>

                      {/*<img*/}
                      {/*  src={item?.content?.image}*/}
                      {/*  width="100%"*/}
                      {/*  alt={item?.content?.title}*/}
                      {/*  className="min-h-auto lg:min-h-[180px]"*/}
                      {/*/>*/}
                      <div className="font-display top-0 w-full h-full absolute flex flex-col justify-center items-center text-center text-white">
                        {item?.content?.subtitle && (
                          <h1 className="banner-top-heading mb-2.5 text-lg font-bold opacity-0">
                            {item?.content?.subtitle}
                          </h1>
                        )}
                        {item?.content?.title && (
                          <h2 className="banner-main-heading mb-2.5 text-5xl font-black opacity-0">
                            {item?.content?.title}
                          </h2>
                        )}
                        {item?.content?.CTAtext && (
                          <div className="animate-wiggle transform origin-center lg:origin-center ">
                            <span className="banner-cta opacity-0">
                              <button
                                onClick={() => {
                                  item?.actions?.CTA &&
                                    getBannerCTA(item?.actions?.CTA);
                                }}
                                type="button"
                                className="btn btn--primary  overflow-hidden lg:btn--lg"
                              >
                                {item?.content?.CTAtext}
                                <span className="waving animate-shine" />
                              </button>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div className="absolute flex flex-row bg-[#f4f1f1] text-[#63686c] w-full text-[9px] font-display justify-center items-center lg:rounded-br-lg lg:rounded-bl-lg">
            <div className="flex items-center lg:mx-4 p-2.5 ">
              <img
                src={`https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1${shield}`}
                alt=""
                className="h-4"
                width={14}
                height={16}
              />
              <span className="mx-2">TRUSTED - SAFE - SECURE</span>
            </div>
            <div className="flex items-center lg:mx-4">
              <span className="mx-2">REGULATED BY</span>
              <img
                src={`https://preprod.playfrank.com/cdn-cgi/image/format=auto,dpr=2,sharpen=1${gamling}`}
                alt=""
                className="h-4"
                width={88}
                height={16}
              />
            </div>
          </div>
          <div className="text-center min-h-[28px] lg:min-h-[36px] p-2.5 mt-[40px] bg-white leading-tight">
            {engaggedBannerDetails?.[activeIndex]?.actions?.TnC?.ActionID &&
              engaggedBannerDetails?.[activeIndex]?.content?.TnC && (
                <div
                  onClick={() => {
                    getBannerCTA(
                      engaggedBannerDetails?.[activeIndex]?.actions?.TnC,
                    );
                  }}
                  className="block leading-none font-display text-[7px] text-[#6f6d6d] cursor-pointer"
                >
                  {engaggedBannerDetails?.[activeIndex]?.content?.TnC}
                </div>
              )}
          </div>
        </div>
      </div>
    </TopBarLogo>
  );
};

export default LandingOffer;
