import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import slugify from 'slugify';
import s from './GameBrowserNavigation.scss';
import GameSearchBar from './GameSearchBar';
import Link from '../Link';
import { resetFilter, setGameCategory } from '../../actions/gamebrowser';
import { openModal } from '../../actions/modal';
import searchIcon from '../../../public/img/icons/search-blue.svg';
import featured from '../../../public/img/icons/featured.svg';

const GameBrowserNavigation = ({
  searchIsopen,
  closeGameSearchBar,
  openGameSearchBar,
  filterIsopen,
  toggleVendorFilter,
}) => {
  // useStyles(s);

  const hasFilter = useSelector(
    (state) => !!state.gamebrowser.filter.vendors.length,
  );
  const categories = useSelector(
    (state) => state.gamebrowser.gameData.categories,
  );
  const selectedSubCategory = useSelector(
    (state) => state.gamebrowser.selectedSubCategory,
  );
  const dispatch = useDispatch();
  const selectGameCategory = (event) => {
    const category = event.currentTarget.getAttribute('data-category');
    const subCategory = event.currentTarget.getAttribute('data-subcategory');
    dispatch(setGameCategory(category, subCategory));
    dispatch(resetFilter());
  };

  const openGameSearchModal = () => dispatch(openModal('GAME_SEARCH_MODAL'));

  return (
    <div className="w-full overflow-auto mb-3 lg:px-0 px-4">
      <div className="flex relative">
        <div
          className={
            'mr-3 border-[3px] cursor-pointer border-[#1970ad] text-[#1970ad] hover:bg-[#1970ad]  hover:text-[#ffffff] rounded-3xl w-10 h-10 relative aspect-[1/1]'
          }
          onClick={openGameSearchModal}
        >
          <svg
            width={21}
            height={20}
            className={
              'absolute left-1.5  top-1/2 transform -translate-y-1/2 cursor-pointer'
            }
          >
            <use xlinkHref={`${searchIcon}#root`} />
          </svg>
        </div>
        <div className="flex flex-row w-full font-display">
          <Link
            to="home"
            transition={false}
            onClick={selectGameCategory}
            // className={cx(s.item, s.desktop, s.mobile, {
            //   [s.active]: selectedSubCategory === '',
            // })}
            className={cx(
              'transition duration-150 ease-in-out hover:bg-[#1970ad] hover:text-[#ffffff] uppercase px-4 py-2 font-bold text-sm rounded-3xl flex items-center justify-center mr-2.5 whitespace-nowrap',
              {
                ['bg-[#1970ad] text-[#ffffff]']: selectedSubCategory === '',
                ['bg-[#d6f3ff] text-[#1970ad]']: selectedSubCategory !== '',
              },
            )}
            data-category=""
            data-subcategory=""
          >
            Home
          </Link>
          {categories.mainCategories.map(
            (item, i) =>
              i === 0 &&
              item.subCategories.map((subCategory) => (
                <Link
                  key={subCategory.name}
                  to="lobby"
                  transition={false}
                  onClick={selectGameCategory}
                  params={{
                    category: slugify(subCategory.name, {
                      replacement: '-',
                      lower: true,
                    }),
                    lobby: 'lobby',
                  }}
                  className={cx(
                    'transition duration-150 ease-in-out hover:bg-[#1970ad] hover:text-[#ffffff] uppercase px-4 py-2 font-bold text-sm rounded-3xl flex items-center justify-center mr-2.5 whitespace-nowrap',
                    {
                      ['bg-[#1970ad] text-[#ffffff]']:
                        !hasFilter &&
                        !filterIsopen &&
                        selectedSubCategory ===
                          slugify(subCategory.name, {
                            replacement: '-',
                            lower: true,
                          }),
                      ['bg-[#d6f3ff] text-[#1970ad]']:
                        selectedSubCategory !==
                          slugify(subCategory.name, {
                            replacement: '-',
                            lower: true,
                          }) ||
                        filterIsopen ||
                        hasFilter,
                    },
                  )}
                  data-category={slugify(item.name, {
                    replacement: '-',
                    lower: true,
                  })}
                  data-subcategory={slugify(subCategory.name, {
                    replacement: '-',
                    lower: true,
                  })}
                >
                  {subCategory.name}
                </Link>
              )),
          )}
          <div
            transition={false}
            onClick={(event) => {
              selectGameCategory(event);
              toggleVendorFilter();
            }}
            className={cx(
              'transition duration-150 ease-in-out hover:bg-[#1970ad] hover:text-[#ffffff] uppercase px-4 py-2 font-bold text-sm rounded-3xl flex items-center justify-center mr-2.5 whitespace-nowrap cursor-pointer',
              {
                ['bg-[#1970ad] text-[#ffffff]']: filterIsopen || hasFilter,
                ['bg-[#d6f3ff] text-[#1970ad]']: !filterIsopen && !hasFilter,
              },
            )}
            data-category="casino"
            data-subcategory="all-games"
          >
            Vendors
          </div>
        </div>
        <div className={''}>
          {/* <GameSearchBar
            searchIsopen={searchIsopen}
            openGameSearchBar={openGameSearchBar}
            closeGameSearchBar={closeGameSearchBar}
          /> */}
          {/* <GameFilterBar
            filterIsopen={filterIsopen}
            toggleGameFilterBar={toggleGameFilterBar}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default GameBrowserNavigation;
