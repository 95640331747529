import React, { useState } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import Grid from './Grid';
import FeaturedGrid from './GridFeatured';
import GameBrowserNavigation from './GameBrowserNavigation';

import {
  gamebrowserSetCategory as gamebrowserSetCategoryFunc,
  resetFilter as resetFilterFunc,
  setGameCategory as setGameCategoryFunc,
  setToTopActive as setToTopActiveFunc,
} from '../../actions/gamebrowser';
import { setGamebrowserActive as setGamebrowserActiveFunc } from '../../actions/navigation';

const GridComponents = {
  GeneralFeaturedGrid: FeaturedGrid,
  GeneralGrid: Grid,
};

const GameBrowserComponent = () => {
  const [filterIsopen, setFilterIsopen] = useState(false);
  const [searchIsopen, setSearchIsopen] = useState(false);
  const [previousSelectedCategory, setPreviousSelectedCategory] =
    useState('FEATURED');
  const [previousSelectedSubCategory, setPreviousSelectedSubCategory] =
    useState('FEATURED');

  const selectedCategory = useSelector(
    (state) => state.gamebrowser.selectedCategory,
  );
  const selectedSubCategory = useSelector(
    (state) => state.gamebrowser.selectedSubCategory,
  );
  const lobbyType = useSelector(
    (state) => state.router.activeRoute.params.lobbyType,
  );

  const dispatch = useDispatch();
  const setGameCategory = (category, subcategory) =>
    dispatch(setGameCategoryFunc(category, subcategory));
  const clearFilter = () => dispatch(resetFilterFunc());
  const setGamebrowserActive = (isActive) =>
    dispatch(setGamebrowserActiveFunc(isActive));
  const gamebrowserSetCategory = (category) =>
    dispatch(gamebrowserSetCategoryFunc(category));
  const setToTopActive = (isActive) => dispatch(setToTopActiveFunc(isActive));
  const showAll = (show) =>
    dispatch({
      type: 'GAMEBROWSER_SHOW_ALL',
      showAll: show,
    });

  // componentWillUnmount() {
  //   setGamebrowserActive(false);
  // }

  const toggleVendorFilter = () => {
    setFilterIsopen(!filterIsopen);
    setSearchIsopen(false);
  };

  const openGameSearchBar = () => {
    setPreviousSelectedCategory(selectedCategory);
    setPreviousSelectedSubCategory(selectedSubCategory);

    setGameCategory('casino', 'all-games');
    showAll(false);

    setSearchIsopen(true);
    setFilterIsopen(false);
  };

  const closeGameSearchBar = () => {
    setGameCategory(
      previousSelectedCategory || 'casino',
      previousSelectedSubCategory || 'all-games',
    );

    setSearchIsopen(false);
    setFilterIsopen(false);
  };

  const closeFilterSeachBar = (category) => {
    gamebrowserSetCategory(category || previousSelectedCategory);
    showAll(false);
    clearFilter();

    setSearchIsopen(false);
    setFilterIsopen(false);
  };

  const inView = (isVisible) => {
    setGamebrowserActive(isVisible);
  };

  const inViewGrid = (isVisible) => {
    setToTopActive(isVisible);
  };

  if (!lobbyType) {
    return null;
  }

  let GridComponent = null;
  if (searchIsopen) {
    GridComponent = GridComponents[`${_.upperFirst(lobbyType)}Grid`];
  } else {
    GridComponent =
      selectedCategory === '' || !selectedCategory
        ? GridComponents[`${_.upperFirst(lobbyType)}FeaturedGrid`]
        : GridComponents[`${_.upperFirst(lobbyType)}Grid`];
  }

  return (
    <VisibilitySensor onChange={inView} partialVisibility>
      <div className="max-w-full w-full" id="gameBrowser">
        <GameBrowserNavigation
          toggleVendorFilter={toggleVendorFilter}
          openGameSearchBar={openGameSearchBar}
          closeGameSearchBar={closeGameSearchBar}
          closeFilterSeachBar={closeFilterSeachBar}
          searchIsopen={searchIsopen}
          filterIsopen={filterIsopen}
        />
        <VisibilitySensor
          onChange={inViewGrid}
          minTopValue={1000}
          partialVisibility
        >
          <GridComponent
            toggleVendorFilter={toggleVendorFilter}
            closeGameSearchBar={closeGameSearchBar}
            searchIsopen={searchIsopen}
            filterIsopen={filterIsopen}
          />
        </VisibilitySensor>
      </div>
    </VisibilitySensor>
  );
};

export default GameBrowserComponent;
