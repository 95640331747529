import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import GridFeaturedCategory from './GridFeaturedCategory';
import s from './GridFeatured.scss';
import hot from './hot.svg';
import cold from './cold.svg';
import { useQuery } from '@apollo/client';
import getRecommendedGamesQuery from '../../actions/get-recommended-games.graphql';
import coldGraphQuery from './cold.graphql';
import { useIntl } from 'react-intl';

const GridFeatured = () => {
  const intl = useIntl();
  const isGamesLoaded = useSelector((state) => state.aspire.isGamesLoaded);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const EncryptedUserID = useSelector((state) => state.user.EncryptedUserID);
  const country = useSelector((state) => state.user.IpCountry.toLowerCase());
  const licenseType = useSelector((state) => state.license.type);

  useStyles(s);
  const [recentGames, setRecentGames] = useState({
    name: 'recent games',
    instantGamesOrder: [],
  });
  const [popularNowList, setPopularNowList] = useState({
    name: 'Popular Now',
    instantGamesOrder: [],
  });
  const [personalGamesList, setPersonalGamesList] = useState({
    name: 'Recommended for you',
    instantGamesOrder: [],
  });

  const { loading, error, data } = useQuery(coldGraphQuery, {
    pollInterval: 10000,
  });
  const {
    loading: recommendedLoading,
    error: recommendedError,
    data: recommendedData,
  } = useQuery(getRecommendedGamesQuery, {
    variables: {
      userId: EncryptedUserID,
      isLoggedIn: isAuthenticated,
      country,
      language: intl.locale,
      regulation: licenseType,
    },
  });

  const categories = useSelector(
    (state) => state.gamebrowser.gameData.categories,
  );
  const games = useSelector((state) => state.gamebrowser.gameData.games);
  useEffect(() => {
    const recentInteractions =
      recommendedData?.getRecommendedGames?.data?.recommendations?.find(
        (entry) => entry.category.name === 'recentInteractions',
      );
    const popularNow =
      recommendedData?.getRecommendedGames?.data?.recommendations?.find(
        (entry) => entry.category.name === 'popularNow',
      );
    const personalGames =
      recommendedData?.getRecommendedGames?.data?.recommendations?.find(
        (entry) => entry.category.name === 'personalGames',
      );

    if (recentInteractions) {
      const list = [];
      recentInteractions?.games.map((game) => list.push(game.gameId));
      setRecentGames({
        name: 'recent games',
        instantGamesOrder: list?.slice(0, 6),
      });
    }

    if (popularNow) {
      const list = [];
      popularNow?.games.map((game) => list.push(game.gameId));
      setPopularNowList({
        name: 'Popular Now',
        instantGamesOrder: list?.slice(0, 6),
      });
    }

    if (personalGames) {
      const list = [];
      personalGames?.games.map((game) => list.push(game.gameId));
      setPersonalGamesList({
        name: 'Recommended for you',
        instantGamesOrder: list?.slice(0, 6),
      });
    }
  }, [recommendedData]);
  useEffect(() => {
    if (isGamesLoaded && isAuthenticated) {
      window.AsgFW.getRecentGames((games) => {
        const recent = games?.map((game) => {
          return game.gameId;
        });
        setRecentGames({
          name: 'recent games',
          instantGamesOrder: recent?.slice(0, 6),
        });
      });
    }
  }, [isGamesLoaded, isAuthenticated]);

  return (
    <div className="w-full" id="GridFeatured">
      {recentGames?.instantGamesOrder.length > 0 && (
        <GridFeaturedCategory
          category={recentGames}
          subCategory={recentGames}
          games={games}
          categoryname={'recent games'}
          key={'recent games'}
          viewMore={false}
        />
      )}
      {popularNowList?.instantGamesOrder.length > 0 && (
        <GridFeaturedCategory
          category={popularNowList}
          subCategory={popularNowList}
          games={games}
          categoryname={'Popular Now'}
          key={'Popular Now'}
          viewMore={false}
        />
      )}
      {personalGamesList?.instantGamesOrder.length > 0 && (
        <GridFeaturedCategory
          category={personalGamesList}
          subCategory={personalGamesList}
          games={games}
          categoryname={'Recommended for you'}
          key={'Recommended for you'}
          viewMore={false}
        />
      )}
      {categories.mainCategories.map(
        (item, i) =>
          i === 0 &&
          item.subCategories.map((subCategory, location) => (
            <React.Fragment key={subCategory.name}>
              <GridFeaturedCategory
                category={subCategory}
                subCategory={subCategory}
                games={games}
                categoryname={subCategory.name}
                location={location}
              />
              {location === 2 && (
                <>
                  {data?.getHourlyHotColdGames?.hot?.instantGamesOrder.length >
                    0 && (
                    <GridFeaturedCategory
                      category={data?.getHourlyHotColdGames?.hot}
                      icon={<img src={hot} height={20} alt="" />}
                      subCategory={data?.getHourlyHotColdGames?.hot}
                      games={games}
                      categoryname={data?.getHourlyHotColdGames?.hot?.name}
                      details={data?.getHourlyHotColdGames?.hot.details}
                      key={data?.getHourlyHotColdGames?.hot?.name}
                      viewMore={false}
                      location={location}
                    />
                  )}
                  {data?.getHourlyHotColdGames?.cold?.instantGamesOrder.length >
                    0 && (
                    <GridFeaturedCategory
                      category={data?.getHourlyHotColdGames?.cold}
                      subCategory={data?.getHourlyHotColdGames?.cold}
                      games={games}
                      icon={<img src={cold} height={20} alt="" />}
                      categoryname={`${data?.getHourlyHotColdGames?.cold?.name} <img src={cold} />`}
                      details={data?.getHourlyHotColdGames?.cold?.details}
                      key={data?.getHourlyHotColdGames?.cold?.name}
                      viewMore={false}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          )),
      )}
    </div>
  );
};

export default GridFeatured;
