import React, { lazy, Suspense } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import HomePublic from './HomePublic';
//import HomeAuthenticated from "./HomeAuthenticated";
import s from './Home.scss';
import { injectIntl } from 'react-intl';
import { Generic, JSONLD } from 'react-structured-data';
import { locales } from '../../config';
import { localeUrl } from '../../core/url';
import { updateAlternate } from '../../core/DOMUtils';

const HomeAuthenticated = lazy(() =>
  import(/* webpackChunkName: 'HomeAuthenticated' */ './HomeAuthenticated'),
);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    routeParams: state.router.activeRoute.params,
  };
};
@injectIntl
@connect(mapStateToProps)
@withStyles(s)
class Home extends React.Component {
  componentDidMount() {
    const { routeParams } = this.props;
    if (routeParams.lobby && routeParams.category) {
      const alternate = {};
      locales.map((lang) => {
        alternate[lang] = `https://www.playfrank.com${localeUrl('lobby', lang, {
          lobby: routeParams.lobby,
          category: routeParams.category,
        })}`;
      });
      updateAlternate(alternate);
    }
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <JSONLD dangerouslyExposeHtml>
          <Generic
            type="website"
            jsonldtype="WebSite"
            schema={{
              url: `https://www.playfrank.com/${intl.locale}`,
              potentialAction: {
                '@type': 'SearchAction',
                target: `https://www.playfrank.com/${intl.locale}?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
              },
            }}
          />
        </JSONLD>
        <JSONLD dangerouslyExposeHtml>
          <Generic
            type="organization"
            jsonldtype="Organization"
            schema={{
              name: 'PlayFrank',
              url: `https://www.playfrank.com/${intl.locale}`,
              email: 'support@playfrank.com',
              knowsLanguage: intl.locale,
              logo: 'https://www.playfrank.com/img/general-logo.svg',
              sameAs: [
                'https://www.facebook.com/PlayFrankCasino/',
                'https://www.instagram.com/playfrankcasino/',
              ],
              address: {
                '@type': 'PostalAddress',
                addressLocality: 'Sliema SLM 1548, Malta',
                streetAddress: '135, High street',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  url: `https://www.playfrank.com/${intl.locale}/faq`,
                  telephone: '+44 203 318 9367',
                  email: 'support@playfrank.com',
                  contactType: 'customer service',
                },
              ],
            }}
          />
        </JSONLD>
        <HomePublic />
      </>
    );
  }
}

export default Home;
