import React, { useEffect, useMemo, useRef } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import useStyles from 'isomorphic-style-loader/useStyles';

import { connect, useDispatch, useSelector } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import cx from 'classnames';
import { defineMessages, injectIntl, useIntl } from 'react-intl';
import s from './GameSearchBar.scss';

import {
  searchGames,
  searchLiveGames,
  setCategory,
} from '../../actions/gamebrowser';
// import search from '../../../public/img/icons/search.svg';
// import search from '../../../public/img/icons/search.svg';
import searchIcon from '../../../public/img/icons/search-blue.svg';
import { closeModal } from '../../actions/modal';
import { trackAction } from '../../actions/analytics';
import close from '../../../public/img/icons/close.svg';

const messages = defineMessages({
  gameOnTypeToSearch: {
    id: 'placeholder.typeToSearch',
    defaultMessage: 'Search games...',
    description: 'The placeholder for the searchfield in the game screen',
  },
  textSearch: {
    id: 'text.search',
    defaultMessage: 'Search',
    description: 'The category name for te gambrowser navigator',
  },
});

// Load the Form
@connect(
  (state) => ({
    gamebrowser: state.gamebrowser,
    isMobile: state.browser.lessThan.large,
    licenseType: state.license.type,
    isAuthenticated: state.auth.isAuthenticated,
    lobbyType: state.router.activeRoute.params.lobbyType,
    activeRoute: state.router.activeRoute.name,
    isGameActive: state.game.isActive,
  }),
  (dispatch) => ({
    closeModal: () => dispatch(closeModal('GAME_FILTER_MODAL')),
    searchGames: (query) => dispatch(searchGames(query)),
    searchLiveGames: (query) => dispatch(searchLiveGames(query)),
    setCategoryGameBrowser: (category, callback) =>
      dispatch(setCategory(category)).then(() => {
        callback();
      }),
    trackAction: (action, params) => dispatch(trackAction(action, params)),
  }),
)
@withStyles(s)
@injectIntl
@withStyles(s)
class GameSearchBarr extends React.Component {
  static defaultProps = {
    isOpen: false,
  };

  constructor(props) {
    super(props);
    this.searchGames = _.debounce(this.props.searchGames, 500);
    this.searchLiveGames = _.debounce(this.props.searchLiveGames, 500);
    this.query = '';
  }

  @boundMethod
  search() {
    const { lobbyType } = this.props;
    this.query = this.input.value;

    if (this.query?.length >= 2) {
      if (lobbyType === 'general') {
        this.searchGames(this.query);
      } else {
        this.searchLiveGames(this.query);
      }

      // Only track if takes more then 5 seconds
      setTimeout(() => {
        trackAction('Search for game', {
          component: 'GameBrowser/GameSearchBar',
          variation: 'Default',
          query,
        });
      }, 1000);
    }
  }

  @boundMethod
  openGameSearchBarIfClosed() {
    const { searchIsopen, openGameSearchBar, trackAction } = this.props;

    if (!searchIsopen) {
      openGameSearchBar();
      trackAction('Open game search bar', {
        component: 'GameBrowser/GameSearchBar',
        variation: 'Default',
      });
    }
  }

  @boundMethod
  closeGameSearchBar() {
    const { closeGameSearchBar, trackAction } = this.props;
    this.input.value = '';
    closeGameSearchBar();
    trackAction('Close game search bar', {
      component: 'GameBrowser/GameSearchBar',
      variation: 'Default',
    });
  }

  render() {
    const { searchIsopen, intl } = this.props;
    return (
      <div className={cx(s.root, { [s.isOpen]: searchIsopen })}>
        <div className={s.searchContainer}>
          <div
            className={cx('formContainer', s.formContainer)}
            onClick={this.openGameSearchBarIfClosed}
          >
            <img src={searchIcon} className={s.icon} />
            <input
              ref={(e) => (this.input = e)}
              name="query"
              id="query"
              type="text"
              autoComplete="off"
              placeholder={intl.formatMessage(messages.gameOnTypeToSearch)}
              onKeyUp={(e) => {
                this.search(e.target.value);
              }}
              className={cx('input', s.searchInput)}
            />
          </div>
          <span onClick={this.closeGameSearchBar} className={s.closeButton}>
            <svg width="20" height="20">
              <use xlinkHref={`${close}#root`} />
            </svg>
          </span>
        </div>
      </div>
    );
  }
}

const GameSearchBar = ({
  searchIsopen,
  openGameSearchBar,
  closeGameSearchBar,
}) => {
  const intl = useIntl();
  useStyles(s);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.gamebrowser.gameData.categories,
  );
  const games = useSelector((state) => state.gamebrowser.gameData.games);

  const openGameSearchBarIfClosed = () => {
    if (!searchIsopen) {
      openGameSearchBar();
      // trackAction('Open game search bar', {
      //   component: 'GameBrowser/GameSearchBar',
      //   variation: 'Default',
      // });
    }
  };

  const search = (e) => {
    const regexForSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    const searchResultSearch = games.filter((game) =>
      game?.name
        ?.trim()
        .replaceAll(regexForSpecialChar, '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        ?.includes(
          ref.current.value
            ?.trim()
            .replaceAll(regexForSpecialChar, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ),
    );

    return dispatch({
      type: 'SET_SELECTED_GAMES',
      games: searchResultSearch.map((item) => item.id),
      selectedCategory: 'Search',
    });

    // const searchResultVendor = _.filter(
    //   result,
    //   _.method('Provider.match', new RegExp(query, 'i')),
    // );
    // const searchResult = _.concat(searchResultSearch, searchResultVendor);
  };
  const debouncedEventHandler = useMemo(() => _.debounce(search, 300), [games]);

  useEffect(() => {
    if (searchIsopen) {
      search();
    }
  }, [searchIsopen]);
  return (
    <div
      className={cx('relative w-10 transition-all overflow-hidden', {
        ['w-56']: searchIsopen,
      })}
    >
      <div className={'flex items-center h-10'}>
        <div
          className={
            'border-[3px] cursor-pointer border-[#18B1E3] rounded-3xl w-full h-10 relative'
          }
          onClick={openGameSearchBarIfClosed}
        >
          <img
            src={searchIcon}
            className={
              'absolute left-1.5  top-1/2 transform -translate-y-1/2 cursor-pointer'
            }
            alt=""
            width={21}
            height={20}
          />
          <input
            ref={ref}
            name="query"
            id="query"
            type="text"
            autoComplete="off"
            placeholder={intl.formatMessage(messages.gameOnTypeToSearch)}
            onKeyUp={debouncedEventHandler}
            className={cx(
              'input !h-8 !py-1 !border-none !pl-10 border-[3px] border-[#18B1E3]',
              {
                ['opacity-0 -z-10 !px-0 cursor-pointer']: !searchIsopen,
              },
            )}
          />
        </div>
        <span
          onClick={closeGameSearchBar}
          className={cx(
            ' absolute right-3 w-4  top-1/2 transform -translate-y-1/2 cursor-pointer',
            { ['hidden']: !searchIsopen },
          )}
        >
          <svg width="16" height="16">
            <use xlinkHref={`${close}#root`} fill="#18B1E3" />
          </svg>
        </span>
      </div>
    </div>
  );
};
export default GameSearchBar;
