import React from 'react';
import cx from 'classnames';
import GameItem from './GameItem';
import GameLabelNew from './GameLabel/GameLabelNew';
import GameLabelPopulair from './GameLabel/GameLabelPopulair';
import GameLabelPercentage from './GameLabel/GameLabelPercentage';

const GameItemCasino = ({
  game,
  id,
  className,
  showLabel = false,
  apolloClient,
  index = 0,
  fullwidth = false,
  loading = 'lazy',
  containerClassName = '',
  details = {},
}) => {
  // console.log('GameItemCasino game', showLabel);
  // console.log('GameItemCasino id', id);

  if (!game) {
    return null;
  }

  return (
    <GameItem
      index={index}
      fullwidth={fullwidth}
      containerClassName={containerClassName}
      className={cx('aspect-w-4 aspect-h-5 rounded-[20px] overflow-hidden ', {
        's.isJackpot': game?.jackpotAmount,
      })}
      game={game}
      showLabel={showLabel}
    >
      <figure className="w-full h-full bg-black/20">
        {/* {(game.externalGameSubProvider || game.externalGameProvider) && (
            <GameVendorImage
              vendor={game.externalGameSubProvider || game.externalGameProvider}
              className="absolute top-1 right-2.5 z-10 w-11 h-3 object-contain object-right"
              alt=""
            />
          )} */}
        {game?.gameConfig?.includes('New') && <GameLabelNew />}
        {game?.tags &&
          game?.tags?.indexOf('populair') > -1 &&
          !game.gameConfig?.includes('New') && <GameLabelPopulair />}
        {details?.[game.id] && (
          <GameLabelPercentage
            percentage={details?.[game.id]?.percentage}
            type={details?.[game.id]?.type}
          />
        )}

        {/* {game.Slug && */}
        {/*  eligableXpMissionGames.indexOf(game.Slug) > -1 && */}
        {/*  !game.newGame && <GameLabelXpMission />} */}
        {/*
          )}
          {details?.[game.id] && <GameLabelPercentage percentage={details?.[game.id]?.percentage} type={details?.[game.id]?.type}/> }
          {/* {game.gameConfig.includes('New') && <GameLabelNew />} */}
        {/* {game.tags && game.tags.indexOf('populair') > -1 && !game.newGame && ( */}
        {/*  <GameLabelPopulair /> */}
        {/* )} */}
        {/* {game.Slug && */}
        {/*  eligableXpMissionGames.indexOf(game.Slug) > -1 && */}
        {/*  !game.newGame && <GameLabelXpMission />} */}
        {/*
          <div className={s.gameNameContainer}>{game.name}</div> */}
        <picture>
          <source
            media="(max-width: 992px)"
            srcSet={`https://www.playfrank.com/cdn-cgi/image/width=140,height=92,format=auto,dpr=2,sharpen=1/${game.images.default.replace(
              'download.gamesrv1.com',
              'www.playfrank.com',
            )}`}
          />
          <source
            media="(min-width: 1025px)"
            srcSet={`https://www.playfrank.com/cdn-cgi/image/width=173,height=114,format=auto,dpr=2,sharpen=1/${game.images.default.replace(
              'download.gamesrv1.com',
              'www.playfrank.com',
            )}`}
          />
          <img
            loading={loading}
            src={`https://www.playfrank.com/cdn-cgi/image/width=173,height=114,format=auto,dpr=2,sharpen=1,onerror=redirect/${game.images.default.replace(
              'download.gamesrv1.com',
              'www.playfrank.com',
            )}`}
            alt=""
            className="w-full h-full object-cover"
            width="212"
            height="265"
            // onError={this.addDefaultSrc}
          />
        </picture>
        {/* {game.PJPBalanceCurrent && ( */}
        {/*  <figcaption> */}
        {/*    <img */}
        {/*      src={jackpot} */}
        {/*      className={s.jackpot} */}
        {/*      alt={`Current jackpot for ${game.name}`} */}
        {/*    /> */}
        {/*    <span> */}
        {/*      {intl.formatNumber(game.PJPBalanceCurrent, { */}
        {/*        style: 'currency', */}
        {/*        currency: game.CurrencyCode, */}
        {/*        maximumFractionDigits: 0, */}
        {/*        minimumFractionDigits: 0, */}
        {/*      })} */}
        {/*    </span> */}
        {/*    <span>{game.description}</span> */}
        {/*  </figcaption> */}
        {/* )} */}
      </figure>
    </GameItem>
  );
};
export default GameItemCasino;
